import '../scss/main.scss';
import $ from 'jquery';
import { createApp } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Vivus from 'vivus';
import LoadingScreen from '../vue/LoadingScreen.vue';

// Vue.js loading screen
createApp(LoadingScreen).mount('#loading_screen');

// jQuery
$(function () {
  console.log('jQuery is ready.');
});

// scroll effect
const headerElement = document.querySelector('.js-header');
const scrollEffect = () => {
  if (10 < window.scrollY) {
    headerElement?.classList.add('__fixed');
  } else {
    headerElement?.classList.remove('__fixed');
  }
};
window.addEventListener('scroll', () => {
  scrollEffect();
});

// news ticker
const newsTickerElement = document.querySelector('.js-news-ticker') as HTMLElement;
const newsTicker = (order = 1) => {
  if (order < 3) {
    newsTickerElement.setAttribute('style', `transform: translateY(${-33.3333 * order}%);`);
    setTimeout(() => {
      newsTicker(++order);
    }, 4000);
  } else {
    newsTickerElement.setAttribute('style', `transform: translateY(0);`);
    setTimeout(() => {
      newsTicker();
    }, 4000);
  }
};
if (newsTickerElement) {
  setTimeout(() => {
    newsTicker();
  }, 4000);
}

// GSAP
gsap.registerPlugin(ScrollTrigger);

// scroll fadein
gsap.fromTo(
  '.js-scroll-animation-fadein--element',
  {
    y: 64,
    autoAlpha: 0
  },
  {
    y: 0,
    autoAlpha: 1,
    delay: 0.1,
    duration: 1.2,
    ease: 'power2.out',
    scrollTrigger: {
      trigger: '.js-scroll-animation-fadein--trigger',
      start: 'top 70%',
      end: 'bottom 60%',
      scrub: true
      // markers: true
    },
    stagger: {
      from: 'start',
      amount: 1.8
    }
  }
);

// load fadein (page header)
window.addEventListener('load', () => {
  gsap.fromTo(
    '.js-load-animation-fadein--element',
    {
      y: 64,
      autoAlpha: 0
    },
    {
      y: 0,
      autoAlpha: 1,
      delay: 0.8,
      duration: 0.8,
      ease: 'power2.out',
      stagger: {
        from: 'start',
        amount: 0.8
      }
    }
  );
  gsap.fromTo(
    '.js-load-animation-fadein-only--element',
    {
      opacity: 0
    },
    {
      opacity: 1,
      delay: 0.1,
      duration: 0.4,
      ease: 'power2.out',
    }
  );
});

// parallax image
gsap.fromTo(
  '.js-scroll-animation-parallax--element',
  {
    y: 0
  },
  {
    y: -100,
    delay: 0.1,
    duration: 6,
    ease: 'none',
    scrollTrigger: {
      trigger: '.js-scroll-animation-parallax--trigger',
      start: 'top 100%',
      end: 'bottom 90%',
      scrub: true
      // markers: true
    }
  }
);

// fixed horizontal slide（members）
const fixedHorizontalTarget = document.querySelector(
  '.js-scroll-animation-fixed-slide-horizontal--element'
) as HTMLElement;
if (fixedHorizontalTarget) {
  let fixedHorizontalScrollWidth =
    fixedHorizontalTarget.parentElement &&
    fixedHorizontalTarget.offsetWidth - fixedHorizontalTarget.parentElement?.offsetWidth;
  window.addEventListener('resize', () => {
    fixedHorizontalScrollWidth =
      fixedHorizontalTarget.parentElement &&
      fixedHorizontalTarget.offsetWidth - fixedHorizontalTarget.parentElement?.offsetWidth;
  });
  gsap.fromTo(
    '.js-scroll-animation-fixed-slide-horizontal--element',
    {
      x: 0
    },
    {
      x: () => `-${fixedHorizontalScrollWidth}`,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-scroll-animation-fixed-slide-horizontal--trigger',
        start: 'top 0%',
        end: () => `+=${fixedHorizontalScrollWidth}`,
        scrub: true,
        // markers: true,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true
      }
    }
  );
}

// fixed vertical slide（features）
const fixedVerticalTargets = document.querySelectorAll('.js-scroll-animation-fixed-slide-vertical--element') as NodeListOf<Element>;
// const scrollToContent = (y: number) => { window.scrollTo({
//   top: y,
//   left: 0,
//   behavior: 'smooth'
// })}
fixedVerticalTargets.forEach((element, index) => {
  ScrollTrigger.create({
    trigger: element,
    toggleActions: 'play reverse play reverse',
    id: `id_${index}`,
    start: `top 0%`,
    end: `+=200%`,
    // markers: true,
    invalidateOnRefresh: true,
    onEnter: () => {
      fixedVerticalTargets.forEach((item) => {
        item.classList.remove('__active');
      });
      fixedVerticalTargets[index].classList.add('__active');
    },
    onEnterBack: () => {
      fixedVerticalTargets.forEach((item) => {
        item.classList.remove('__active');
      });
      fixedVerticalTargets[index].classList.add('__active');
    }
  });
  // console.log(element.getClientRects()[0].top)
});

// drawing animation
const drawAnimationElement = document.querySelector('#scroll_animation_drawing_element') as HTMLElement;
if (drawAnimationElement) {
  const drawAnimation = new Vivus(
    'scroll_animation_drawing_element',
    {
      type: 'oneByOne',
      duration: 200,
      forceRender: false
    }
    // function() {
    //   console.log('vivusが終了')
    // }
  );
  gsap.fromTo(
    '.js-scroll-animation-drawing--element',
    {
      autoAlpha: 0
    },
    {
      autoAlpha: 1,
      delay: 0.1,
      duration: 0.2,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-scroll-animation-drawing--trigger',
        start: 'top 40%',
        end: 'bottom 10%',
        // markers: true,
        onEnter: () => {
          drawAnimation.stop().reset().play(0.2);
        },
        onLeave: () => {
          drawAnimation.stop().finish();
        },
        // onEnterBack: () => {
        // },
        onLeaveBack: () => {
          drawAnimation.stop().finish().play(-2);
        }
      }
    }
  );
}

// drawer navi
const drawerNavi = document.querySelector('.js-drawer-navi');
const buttonDrawerOpen = document.querySelector('.js-drawer-open');
const buttonDrawerClose = document.querySelector('.js-drawer-close');
const showDrawer = () => {
  drawerNavi?.classList.add('__shown');
  gsap.fromTo(
    '.js-sequential-animation-navi-item',
    {
      x: -24,
      autoAlpha: 0
    },
    {
      x: 0,
      autoAlpha: 1,
      delay: 0.4,
      duration: 0.4,
      ease: 'power2.out',
      stagger: {
        each: 0.2
      }
    }
  );
};
const closeDrawer = () => {
  drawerNavi?.classList.remove('__shown');
  gsap.set('.js-sequential-animation-navi-item', {
    x: -24,
    autoAlpha: 0
  });
};
buttonDrawerOpen?.addEventListener('click', () => {
  showDrawer();
});
buttonDrawerClose?.addEventListener('click', () => {
  closeDrawer();
});
window.addEventListener('resize', () => {
  closeDrawer();
});
